import type { Ref } from 'vue'

export function layoutByDevice() {
  return useDevice().isDesktop ? 'desktop' : 'mobile'
}

export const useLayoutSize = ({
  header,
}: {
  header: Ref<HTMLElement | null>
}) => {
  const appStore = useAppStore()

  const { height } = useElementSize(header)

  watchEffect(() => {
    appStore.header.height = height.value
  })

  onUnmounted(() => {
    appStore.header.height = 0
  })

  return {
    //
  }
}
